import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './dark/Home';
import About from './elements/About';
import Contact from './elements/Contact';
import error404 from './elements/error404';
import './index.scss';
import * as serviceWorker from './serviceWorker';

const Root = () => {
	return (
		<BrowserRouter basename={'/'}>
			<Switch>
				<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
				<Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
				<Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
				<Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
				<Route component={error404} />
			</Switch>
		</BrowserRouter>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
