import React, { Component } from 'react';
import Slider from 'react-slick';
import { portfolioSlick2 } from '../../../page-demo/script';

const PortfolioList = [
	{
		image: 'image-1',
		category: 'Research & Development',
		title: 'AI & ML Models for Custom Media',
	},
	{
		image: 'image-2',
		category: 'Development',
		title: 'Offline Medical Research Analysis Progressive Web Application',
	},
	{
		image: 'image-3',
		category: 'Development',
		title: 'Developer Media Conference API Platform',
	},
	{
		image: 'image-4',
		category: 'Marketing',
		title: '360° Content Marketing Campaign',
	},
	{
		image: 'image-5',
		category: 'Business Strategy',
		title: 'Travel Industry Startup: Traveleum Business Branding',
	},
	{
		image: 'image-9',
		category: 'Development',
		title: 'The Tribune Magazine Security Audit',
	},
];

class Portfolio extends Component {
	render() {
		let title = 'Our Works',
			description = 'Some of our featured portfolio, to read the case-studies, pick one and get in touch to sign an NDA.';
		return (
			<React.Fragment>
				<div className="portfolio-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<div className="section-title">
									<h2>{title}</h2>
									<p>{description}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="portfolio-slick-activation mt--70 mt_sm--40">
						<Slider {...portfolioSlick2}>
							{PortfolioList.map((value, index) => (
								<div className="portfolio" key={index}>
									<div className="thumbnail-inner">
										<div className={`thumbnail ${value.image}`}></div>
										<div className={`bg-blr-image ${value.image}`}></div>
									</div>
									<div className="content">
										<div className="inner">
											<p>{value.category}</p>
											<h4>
												<a href="/contact">{value.title}</a>
											</h4>
											<div className="portfolio-button">
												<a className="rn-btn" href="/contact">
													Case Study
												</a>
											</div>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Portfolio;
