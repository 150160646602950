import React, { Component } from 'react';

class About extends Component {
	render() {
		let title = 'About',
			description = `Worwox is a Group of Agencies™ has been revived & re-invented under one name. Currently, we are a group of 7 agencies & startups with people around the world and sites serving pages every second. Not only do we love open-source, but our developers contribute a lot to open-source from the likes of Node.js & Linux Foundation, GitHub, WordPress Core, React Core, and in projects from Google, Amazon, Microsoft, etc.  We are not traditional in our thinking, hiring, or our business model. So if the shoe fits, let's start walking.`;
		return (
			<>
				<div className="about-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-5 col-md-12">
								<div className="thumbnail">
									<img className="w-100" src="/assets/images/about/about-1.jpg" alt="Worwox" />
								</div>
							</div>

							<div className="col-lg-7 col-md-12">
								<div className="about-inner inner">
									<div className="section-title">
										<h2 className="title">{title}</h2>
										<p className="description">{description}</p>
									</div>
									<div className="row mt--30 mt_sm--10">
										<div className="col-lg-6 col-md-12 col-sm-12 col-12">
											<div className="about-us-list">
												<h3 className="title">Philosophy</h3>
												<p>
													At Worwox, we realize if there’s one thing you can count on, it’s change. We
													also realize that if you can master this change, the opportunities are
													limitless. In a nutshell, that’s what we do. Help you adopt to digital.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-md-12 col-sm-12 col-12">
											<div className="about-us-list">
												<h3 className="title">Values</h3>
												<p>
													We believe in focus, global presence and profitable growth. By capitalizing on
													these strategic drivers, we are generating strong, double-digit growth in
													earnings from continuing operations. Honest and Loyal.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default About;
