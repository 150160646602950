import React, { Component } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

class Header extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
		//  this.subMetuTrigger = this.subMetuTrigger.bind(this);
		window.addEventListener('load', function () {
			console.log('All assets are loaded');
		});
	}

	menuTrigger() {
		document.querySelector('.header-wrapper').classList.toggle('menu-open');
	}

	CLoseMenuTrigger() {
		document.querySelector('.header-wrapper').classList.remove('menu-open');
	}

	render() {
		var elements = document.querySelectorAll('.has-droupdown > a');
		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement.querySelector('.submenu').classList.toggle('active');
					this.classList.toggle('open');
				};
			}
		}
		const { color = 'default-color' } = this.props;

		return (
			<header className={`header-area formobile-menu header--transparent ${color}`}>
				<div className="header-wrapper" id="header-wrapper">
					<div className="header-left">
						<div className="logo">
							<a href="/">
								<img src="/assets/images/logo/logo.png" alt="Worwox" />
							</a>
						</div>
					</div>
					<div className="header-right">
						<nav className="mainmenunav d-lg-block">
							<ul className="mainmenu">
								<li>
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/about">About</Link>
								</li>
								<li>
									<Link to="/contact">Contact</Link>
								</li>
							</ul>
						</nav>
						<div className="header-btn">
							<a className="rn-btn" href="/contact">
								<span>HIRE NOW</span>
							</a>
						</div>
						<div className="humberger-menu d-block d-lg-none pl--20">
							<span onClick={this.menuTrigger} className="menutrigger text-white">
								<FiMenu />
							</span>
						</div>
						<div className="close-menu d-block d-lg-none">
							<span onClick={this.CLoseMenuTrigger} className="closeTrigger">
								<FiX />
							</span>
						</div>
					</div>
				</div>
			</header>
		);
	}
}
export default Header;
