import React, { Component } from 'react';
import { FiChevronUp, FiMail, FiMapPin } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import BrandTwo from '../elements/BrandTwo';

class Contact extends Component {
	static defaultProps = {
		center: {
			lat: 59.95,
			lng: 30.33,
		},
		zoom: 11,
	};

	render() {
		return (
			<React.Fragment>
				<PageHelmet pageTitle="Contact" />

				<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

				<div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" data-black-overlay="6">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100">
									<h2 className="title theme-gradient">Contact</h2>
									<p>Let's help you get in touch and talk business.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="rn-contact-top-area ptb--120 bg_color--5">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
								<div className="rn-address">
									<div className="icon">
										<FiMail />
									</div>
									<div className="inner">
										<h4 className="title">Email Address</h4>
										<p>
											Please do not use our postal mail or contact form for billing questions, support, or
											complaints instead contact the assigned project manager.
										</p>
										<p>
											<b>
												<a href="mailto:info@worwox.com">info@worwox.com</a>
											</b>
										</p>
										<p>
											<a href="mailto:hr@worwox.com">hr@worwox.com</a>
										</p>
									</div>
								</div>
							</div>

							<div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
								<div className="rn-address">
									<div className="icon">
										<FiMapPin />
									</div>
									<div className="inner">
										<h4 className="title">Location</h4>
										<p>
											We are now a <b>fully remote company</b>. Everyone works from the location they
											choose.
										</p>
										<br />
										<p>
											<small>
												<b>OLD ADDRESS</b>: 2nd Floor, 25CCA Commercial Plaza, Sector DD, Phase# 4, DHA
												Lahore, Punjab 54750
											</small>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="rn-brand-area brand-separation bg_color--5 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>

				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>

				<Footer />
			</React.Fragment>
		);
	}
}
export default Contact;
