import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import BrandTwo from '../elements/BrandTwo';
import Breadcrumb from '../elements/common/Breadcrumb';
import CounterOne from '../elements/counters/CounterOne';
import Testimonial from '../elements/Testimonial';

class About extends Component {
	render() {
		let title = 'About',
			description = `Worwox is a Group of Agencies™ has been revived & re-invented under one name. Currently, we are a group of 7 agencies & startups with people around the world and sites serving pages every second. Not only do we love open-source, but our developers contribute a lot to open-source from the likes of Node.js & Linux Foundation, GitHub, WordPress Core, React Core, and in projects from Google, Amazon, Microsoft, etc.  We are not traditional in our thinking, hiring, or our business model. So if the shoe fits, let's start walking.`;
		return (
			<React.Fragment>
				<PageHelmet pageTitle="About" />

				<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
				<Breadcrumb title={'About'} />

				<div className="rn-about-area ptb--120 bg_color--1">
					<div className="rn-about-wrapper">
						<div className="container">
							<div className="row row--35 align-items-center">
								<div className="col-lg-5">
									<div className="thumbnail">
										<img className="w-100" src="/assets/images/about/about-1.jpg" alt="Worwox" />
									</div>
								</div>
								<div className="col-lg-7">
									<div className="about-inner inner">
										<div className="section-title">
											<h2 className="title">{title}</h2>
											<p className="description">{description}</p>
										</div>
										<div className="row mt--30 mt_sm--10">
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Philosophy</h3>
													<p>
														At Worwox, we realize if there’s one thing you can count on, it’s change.
														We also realize that if you can master this change, the opportunities are
														limitless. In a nutshell, that’s what we do. Help you adopt to digital.
													</p>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-12 col-12">
												<div className="about-us-list">
													<h3 className="title">Values</h3>
													<p>
														We believe in focus, global presence and profitable growth. By
														capitalizing on these strategic drivers, we are generating strong,
														double-digit growth in earnings from continuing operations. Honest and
														Loyal.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="rn-counterup-area pb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<h3 className="fontWeight500">Who's Keeping Count?</h3>
								</div>
							</div>
						</div>
						<CounterOne />
					</div>
				</div>

				<div className="rn-finding-us-area rn-finding-us bg_color--1">
					<div className="inner">
						<div className="content-wrapper">
							<div className="content">
								<h4 className="theme-gradient">Work with us</h4>
								<p>
									We are a small close knit team of software developers, engineers, and entrepreneurs. Tell us
									why you'd like to join us
								</p>
								<a className="rn-btn btn-white" href="/contact">
									Connect with HR
								</a>
							</div>
						</div>
						<div className="thumbnail">
							<div className="image">
								<img src="/assets/images/about/finding-us-01.png" alt="Finding Images" />
							</div>
						</div>
					</div>
				</div>

				<div className="rn-testimonial-area bg_color--5 ptb--120">
					<div className="container">
						<Testimonial />
					</div>
				</div>

				<div className="rn-brand-area brand-separation bg_color--5 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div>

				<div className="backto-top">
					<ScrollToTop showUnder={160}>
						<FiChevronUp />
					</ScrollToTop>
				</div>

				<Footer />
			</React.Fragment>
		);
	}
}
export default About;
