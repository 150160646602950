import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
const Testimonial = (props) => {
	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<Tabs>
						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										Worwox has been our valued partner since 2013. We work with their engineers day in and day
										out to advance the HotelTech Hiring platform and support our recruiters across the world.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Babar Khalil</span> - CEO, Network Solutions, QA
									</h6>
								</div>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										They delivered a high-quality product with excellent rebranding of W&N Consulting. We’ve
										had other teams approach us, asking to use us for the similar product.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Stephen A. </span> - COO, W&N Consulting.
									</h6>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										We started working with Worwox Group in mid-2014. Our company was looking for an
										organization that surpassed both competence and communication. Worwox Group of Agencies
										helped us in identifying a major scope creep hence reducing the execution risk. Their
										communication is articulate and frequent, which takes away a lot of the challenges of
										managing our product from afar.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Kisa P. </span> - COO, JustWrite, INC.
									</h6>
								</div>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										I found Worwox Group through a referral from one of their existing client. And I call
										myself lucky to have been directed to them. Their leadership principals and strong work
										ethics deliver the utmost value for my company. They have a team of skilled engineers who
										are actively engaged to help my business grow. With an incredible business sense and
										presence, I fully believe in their recommendations and way of doing things.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Mary S. Watson </span> - VP Eng, WP Content Studio
									</h6>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										InspiryThemes is a Power Elite Envato Author with $2M+ of WordPress themes business. We
										sell niche themes. While looking for an outreach content marketing campaign partner we
										found Worwox Group of Agencies. Their experienced team of editors and writers drove our
										writing campaign and hit the road running for us. Their efforts helped us get better
										search engine rankings and better yields.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Saqib Sarwar </span> - CEO, InspiryThemes, Ltd.
									</h6>
								</div>
							</div>
						</TabPanel>

						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										Worwox Group is a trusted team of professional development partners we have ever worked
										with. They have been with us for the last 3 years and not even for once we regret teaming
										up with them. They will not only do what we ask them to do instead they always strive hard
										to understand our goals and recommend innovative approaches to get even better results.
										Both their design and development team is super connected and responsive.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Amar Rathor </span> - SVP, Heftlink Investments.
									</h6>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							<div className="rn-testimonial-content text-center">
								<div className="inner">
									<p>
										We wanted to build a complete custom eCommerce solution and Worwox Group helped us achieve
										that with WordPress and WooCommerce. Their top-notch development and deployment services
										have helped us generate significant revenue and receive community presence. We attribute
										all of this to Worwox’s incredible team effort and support. Their team members are
										proactive, collaborative, and responsive.
									</p>
								</div>
								<div className="author-info">
									<h6>
										<span>Fatima M. </span> - COO, WooSh Enterprises.
									</h6>
								</div>
							</div>
						</TabPanel>

						<TabList className="testimonial-thumb-wrapper">
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
							<Tab>
								<div className="testimonial-thumbnai">
									<div className="thumb">
										<img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images" />
									</div>
								</div>
							</Tab>
						</TabList>
					</Tabs>
				</div>
			</div>
		</>
	);
};
export default Testimonial;
