import React, { Component, Fragment } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CounterOne extends Component {
	state = {
		didViewCountUp: false,
	};
	onVisibilityChange = (isVisible) => {
		if (isVisible) {
			this.setState({ didViewCountUp: true });
		}
	};
	render() {
		let Data = [
			{
				countNum: 12,
				countTitle: 'Over a decade in this business, helps give us a unique perspective.',
			},
			{
				countNum: 631,
				countTitle: 'Projects completed to date from small startups to large enterprise.',
			},
			{
				countNum: 4190121,
				countTitle: 'Millions of downloads by millions of developers using our open-source.',
			},
		];

		return (
			<Fragment>
				<div className="row">
					{Data.map((value, index) => (
						<div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
							<h5 className="counter">
								<VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
									<CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
								</VisibilitySensor>
							</h5>
							<p className="description">{value.countTitle}</p>
						</div>
					))}
				</div>
			</Fragment>
		);
	}
}
export default CounterOne;
