import React, { Component } from 'react';
import { FiCode, FiFeather, FiTv, FiUsers } from 'react-icons/fi';

const ServiceList = [
	{
		icon: <FiFeather />,
		title: `Business Strategy`,
		description: `Lacking in direction, need business advice or a complete go-to market strategy. Get in touch.`,
	},
	{
		icon: <FiCode />,
		title: `Website Development`,
		description: `Let us help you create a responsive website that's fast, responsive, and built with best practices.`,
	},
	{
		icon: <FiTv />,
		title: `Marketing & Reporting`,
		description: `Brand SEO & SMM marketing services to generate content of all forms and e-mail marketing.`,
	},
	{
		icon: <FiUsers />,
		title: `Developer Relations`,
		description: `We help you understand your developer community and win it by building platform engagement.`,
	},
];

class ServiceTwo extends Component {
	render() {
		let title = 'Services',
			description = 'We provide 360° software development, marketing, and business strategy services.';
		return (
			<>
				<div className="row">
					<div className="col-lg-4 col-12">
						<div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
							<h2 className="title">{title}</h2>
							<p>{description}</p>
							<div className="service-btn">
								<a className="btn-transparent rn-btn-dark" href="/contact">
									<span className="text">Request Custom Service</span>
								</a>
							</div>
						</div>
					</div>
					<div className="col-lg-8 col-12 mt_md--50">
						<div className="row service-one-wrapper">
							{ServiceList.map((val, i) => (
								<div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
									<a href="/contact">
										<div className="service service__style--2">
											<div className="icon">{val.icon}</div>
											<div className="content">
												<h3 className="title">{val.title}</h3>
												<p>{val.description}</p>
											</div>
										</div>
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ServiceTwo;
