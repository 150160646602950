import React, { Component } from 'react';
import { FiCode, FiFeather, FiTv } from 'react-icons/fi';

const ServiceList = [
	{
		icon: <FiFeather />,
		title: 'Business Strategy',
		description:
			'We help you realize the best possible go-to market and business strategy & planning for both enterprise and startups.',
	},
	{
		icon: <FiCode />,
		title: 'Web & Mobile Development',
		description:
			'Worwox is a pioneer in open-source and cutting-edge web & mobile applications software development and support.',
	},
	{
		icon: <FiTv />,
		title: 'Marketing & Reporting',
		description:
			'We offer a 360° brand development, SEO & SMM, content & video production, social media and e-mail marketing.',
	},
];

class ServiceOne extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="row">
					{ServiceList.map((val, i) => (
						<div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
							<div className="service service__style--1">
								<div className="icon">{val.icon}</div>
								<div className="content">
									<h4 className="title">{val.title}</h4>
									<p>{val.description}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</React.Fragment>
		);
	}
}
export default ServiceOne;
