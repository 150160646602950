import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = (props) => {
	const title = props.pageTitle;
	const image = `https://worwox.com/assets/images/worwox.png`;
	const url = `https://worwox.com`;
	const description =
		'Worwox is a Group of Agencies™ which has been revived & re-invented under one name. Currently, we are a group of 7 agencies & startups with people around the world and sites serving pages every second. We started in a living room back in 2008 and had been steadily working to build a strong base to stand out of the crowd. Not only we love open source, we have our developers contributing a lot in open source libraries and using repositories like GitHub, WordPress Repository etc. We also create websites, brands, run marketing campaigns & blogs and many other related services.';

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/manifest.json" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#67149e" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:url" content={url} />
				<meta property="og:site_name" content={title} />
				<meta property="article:section" content={title} />
				<meta property="og:image" content={image} />
				<meta property="og:image:secure_url" content={image} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:url" content={url} />
				<meta name="twitter:image:alt" content={title} />
				<meta name="twitter:image" content={image} />
				<meta name="twitter:image:1200" content={image} />
				<meta name="twitter:image:630" content={image} />
				<link rel="canonical" href={url} />
			</Helmet>
		</>
	);
};

export default PageHelmet;
