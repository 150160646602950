import React from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import Helmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import About from '../component/HomeLayout/homeOne/About';
import Portfolio from '../component/HomeLayout/homeOne/Portfolio';
import SliderOne from '../component/slider/SliderOne';
import BrandTwo from '../elements/BrandTwo';
import CounterOne from '../elements/counters/CounterOne';
import ServiceTwo from '../elements/service/ServiceTwo';
import Testimonial from '../elements/Testimonial';

const MainDemo = () => {
	return (
		<div className="active-dark">
			<Helmet pageTitle="Worwox Group of Agencies" />
			<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

			<div className="slider-wrapper">
				<SliderOne />
			</div>

			<div className="about-area about-position-top pb--120 bg_color--1">
				<About />
			</div>

			<div className="service-area ptb--80  bg_image bg_image--3">
				<div className="container">
					<ServiceTwo />
				</div>
			</div>

			<div className="portfolio-area ptb--120 bg_color--1">
				<div className="portfolio-sacousel-inner mb--55">
					<Portfolio />
				</div>
			</div>

			<div className="rn-counterup-area pt--25 pb--110 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title text-center">
								<h3 className="fontWeight500">Who's keeping count?</h3>
							</div>
						</div>
					</div>
					<CounterOne />
				</div>
			</div>

			<div className="rn-testimonial-area bg_color--5 ptb--120">
				<div className="container">
					<Testimonial />
				</div>
			</div>

			<div className="rn-brand-area brand-separation pb--120">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<BrandTwo />
						</div>
					</div>
				</div>
			</div>
			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>

			<Footer />
		</div>
	);
};
export default MainDemo;
